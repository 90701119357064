<template>
    <div class="wpanle acnav-wrap" style="margin-bottom:20px;">
        <div style="padding: 0 20px; background: white;">
            <el-menu :default-active="statusNavActiveIndex" class="waltew-nav" mode="horizontal">
                <el-menu-item index="1" @click="toggleMenu(1)">
                    <a href="javascript:;">{{titleDescript}}</a>
                </el-menu-item>
                <!-- <el-menu-item index="2"
                              @click="toggleMenu(2)">
                    <a href="javascript:;">我的佣金</a>
                </el-menu-item>-->
            </el-menu>
        </div>
        <router-view></router-view>
    </div>
</template>

<script>
let id = 0;
export default {
    name: "walletList",
    metaInfo() {
        return {
            title: "我的钱包 - 询龙网",
            meta: [
                {
                    name: "keywords",
                    content: ""
                }, {
                    name: "description",
                    content: ''
                }
            ]
        }
    },
    data() {
        return {
            statusNavActiveIndex: "1" // 项目状态当前选择
        };
    },
    computed: {
        titleDescript: function() {
            if (this.$route.query.key == 1) {
                return "我的钱包";
            } else {
                return "返回钱包";
            }
        }
    },
    methods: {
        routerTo(params) {
            this.$router.push("/geekuc/wallet/myWallet");
        },
        toggleMenu(val) {
            if (val == 1) {
                this.statusNavActiveIndex = "1";
                this.$router.push("/user/wallet/list?key=1");
            } else {
                this.statusNavActiveIndex = "2";
                this.$router.push("/user/wallet/commission?key=2");
            }
        }
    },
    beforeRouteEnter(to, from, next) {
        next(vm => {
            if (to.name == "userWalletCommission") {
                vm.statusNavActiveIndex = "2";
            }
        });
    }
};
</script>

<style lang="less">
@import "../../../styles/mywallet.less";
.el-tabs__item {
    height: 60px;
    line-height: 60px;
}
</style>




